import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PageRenderer from './PageRenderer';
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Import ThemeProvider and createTheme

console.log("App");

const theme = createTheme(); // Create a theme instance (you can customize it if needed)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="*" element={<PageRenderer />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
