import React from 'react';
import styles from './Loading.module.css'; // Create and import a CSS module for loading styles

const Loading = () => (
  <div className={styles.loadingContainer}>
    <div className={styles.spinner}></div>
    <p>Loading...</p>
  </div>
);

export default Loading;