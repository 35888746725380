import React, { useEffect, useState } from 'react';
import styles from './Header.module.css';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Hamburger icon
import CloseIcon from '@mui/icons-material/Close'; // Close icon

const Header = () => {
  const [currentTime, setCurrentTime] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const updateClock = () => {
      const date = new Date();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      setCurrentTime(`${hours}:${minutes}`);
    };

    updateClock();
    const intervalId = setInterval(updateClock, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const [today, setToday] = useState('');

  useEffect(() => {
    const formatDate = () => {
      const date = new Date();
      const formattedDate = date.toLocaleDateString(i18n.language, {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      });
      setToday(formattedDate);
    };

    formatDate();
    // Listen for language changes and update the date accordingly
    i18n.on('languageChanged', formatDate);

    return () => {
      i18n.off('languageChanged', formatDate);
    };
  }, [i18n]);

  const menuItems = [
    t('Real Estate'),
    t('Travel'),
    t('Investments'),
    t('Vehicles'),
    t('Reviews'),
  ];

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar position="static" className={styles.appBar}>
        {/* Top stripe with date and time */}
        <div className={styles.topStripe}>
          <div className={styles.dateTime}>
            <span className={styles.date}>{today}</span>
            {/*<span className={styles.time}>{currentTime}</span>*/}
          </div>
        </div>

        {/* Main header stripe with logo and menu button */}
        <Toolbar className={styles.toolbar}>
          {/* Menu Button */}
          <IconButton
            edge="start"
            className={styles.menuButton}
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          {/* Logo */}
          <div className={styles.logoContainer}>
            <img src="logo.png" alt="Logo" className={styles.logo} />
          </div>
        </Toolbar>

        {/* Drawer */}
        <Drawer
          anchor={i18n.language === 'he' ? 'right' : 'left'}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          <div className={styles.drawer} role="presentation">
            {/* Close Button */}
            <IconButton
              className={styles.drawerCloseButton}
              onClick={toggleDrawer(false)}
            >
              <CloseIcon />
            </IconButton>

            {/* Logo at the top of the Drawer */}
            <div className={styles.drawerLogoContainer}>
              <img src="logo.png" alt="Logo" className={styles.drawerLogo} />
            </div>

            {/* Menu Items */}
            <List
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              {menuItems.map((text, index) => (
                <ListItem button key={index}>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </AppBar>
    </>
  );
};

export default Header;
