import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from './components/Header';
import Loading from './components/Loading'; // Import the Loading component
import styles from './PageRenderer.module.css';
import { useTranslation } from 'react-i18next';
import sample from './sample.json';

const SingleBlog = lazy(() => import('./components/SingleBlog'));
const AdsComponent = lazy(() => import('./components/AdsComponent'));

const PageRenderer = () => {
  let [data, setData] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  console.log("PageRenderer");

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/csrf/` );
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCsrfToken();
  }, []);





  useEffect(() => {
    
    const fetchData = async () => {
      if (!csrfToken) return;

      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/blogger/getData/`, {
          url: location.pathname,
          command: 'single_blog'
        }, {
          headers: {
            'X-CSRFToken': csrfToken
          }
        });
        setData(response.data);
        console.log(response.data);
       
        // Handle language
        const language = response.data.main_blog.language;
        i18n.changeLanguage(language === 'Hebrew' ? 'he' : 'en');

        // Handle Taboola pixel
        const accountId = response.data.account_id;
        if (accountId) {  // Check if accountId exists and is not an empty string
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.innerHTML = `
            window._tfa = window._tfa || [];
            window._tfa.push({notify: 'event', name: 'page_view', id: ${accountId}});
            !function (t, f, a, x) {
              if (!document.getElementById(x)) {
                t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
              }
            }(document.createElement('script'),
            document.getElementsByTagName('script')[0],
            '//cdn.taboola.com/libtrc/unip/${accountId}/tfa.js',
            'tb_tfa_script');
          `;
          document.head.appendChild(script);
          
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (csrfToken) {
      fetchData();
    }
  }, [csrfToken, i18n, location.pathname]);  // Added location.pathname to the dependency array



  //data=sample   // ################################################################################################## delete this line

  if (!data) {     // ################################################################################################## delete false
    return <Loading />;
  }

  return (
    <div>
      <Header />
      <div className={styles.wrapper}>
        <Suspense fallback={<Loading />}>
          <SingleBlog 
          creative={data.main_blog} 
          email={data.email}
          data = {data}
          />
          <AdsComponent ads={data.random_blogs} />
        </Suspense>
      </div>
    </div>
  );
};

export default PageRenderer;
